@import url('https://fonts.googleapis.com/css2?family=Figtree');

html {
  background-color: #244259;
  max-height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
}

body {
  background-color: #244259;
  font-family: 'Figtree', sans-serif;
  font-weight: 100;
  font-size: small;
}

a {
  text-decoration: none;
  color: #bccdda;
}

a:hover {
  color: white; 
}

footer {
  width: 100%;
  padding: 15px;
  color: white !important;
}

.navbar {
  position: fixed;
  max-width: 100vw;
  width: 100%;
  z-index: 1;
  /* backdrop-filter: blur(3px);
  background-color: rgb(36 66 89 / 80%); */
}

.nav-link {
  color: #bccdda;
  padding: 8px 15px !important;
}

.nav-link:hover {
  color: #aabbc9af;
}

.nav-link.active {
  color: #bccdda !important;
}

.nav-link.active:hover {
  color: #aabbc9af !important;
}

.nav-link:focus {
  color: #bccdda !important;
}

.navbar-toggler {
  border: 1px solid #bccdda1c;
  color: #bccdda !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: transparent !important;
  box-shadow: none;
}

.logo {
  padding: 12px 10px 10px 10px;
  margin-left: 5px;
  color:#bccdda;
  text-decoration: none;
}

.logo:hover {
  cursor: pointer;
  color: #aabbc9af;
  text-decoration: none;
}

.logo-name {
  padding-left: 10px;
}

.offcanvas.show {
  background-color: #bccdda;
}

.offcanvas.show .offcanvas-body {
  padding-right: 60%;
}

.offcanvas.show .offcanvas-body a {
  color:#244259;
}

.offcanvas.show .btn {
  margin-top: 20px;
  border: 1px solid #244259;
}

.offcanvas-toggling {
  background-color: #bccdda;
}

.offcanvas-toggling .offcanvas-body {
  padding-right: 60%;
}

.offcanvas-toggling .offcanvas-body a {
  color:#244259;
}

.offcanvas-toggling .btn {
  margin-top: 20px;
  border: 1px solid #244259;
}

#blur-top {
  height: 175px;
  position: fixed;
  top: 0;
  width: 100vw;
  -webkit-mask-image: linear-gradient(#244259 30%,transparent 100%);
  z-index: 0;
  -webkit-backdrop-filter: blur(4px);
  /* backdrop-filter: blur(4px); */
  background-color: #244259;
  z-index: 0;
  pointer-events: none;
}

#blur-bottom {
  height: 75px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  -webkit-mask-image: linear-gradient(to top,#244259 10%,transparent 100%);
  z-index: 0;
  -webkit-backdrop-filter: blur(4px);
  /* backdrop-filter: blur(4px); */
  background-color: #244259;
  z-index: 0;
  pointer-events: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  right: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border: solid 3px transparent;
  position: absolute;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bccdda45; 
  border-radius: 3px;
  border: solid 3px transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aabbc9af; 
}

.btn {
  font-size: small;
  background-color: transparent;
  border: 1px solid #bccdda1c;
  color: #bccdda;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn:focus {
  background-color: transparent;
  border: 1px solid #bccdda1c;
  color: #bccdda;
}

.btn:hover {
  background-color: #182a36;
  border: 1px solid #182a36;
  color: #bccdda;
}

.container {
  background-color: #244259;
  color: #bccdda;
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: -1;
  max-width: 100vw;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

footer {
  margin: 30px 0px 75px 0px;
  text-align: center;
}

.section {
  margin: auto;
  max-width: 100vw;
  justify-content: center;
  padding: 0px 0px;
}

