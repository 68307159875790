

@media (min-width: 768px) {
    #phone-links {
        display: none;
    }
}

#phone-links {    
    bottom: 0px;
    position: absolute;
    left: 0px;
    padding: 15px 30px;
}

#phone-socials {
    margin: 20px 0px;
}

#phone-socials a {
    font-size: large;
    margin-right: 25px;
}

#phone-email a {
    text-decoration: none;
}