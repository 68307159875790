#title {
    min-height: 100vh;
    display: flex;
}

#title-row {
    align-self: center;
    min-width: 100%;
}

#hello {
    padding-left: 5px;
    /* color: #c35a5az; */
    color: #74c3ff;
}

#name {
    padding: 15px 0px 0px 0px;
    font-size: 70px;
}

#period {
    color: #426178;
}

#tagline {
    margin-top: -10px;
    padding-left: 4px;
    color: #73808a;
}

#description {
    padding: 15px 0px 0px 0px;
    padding-left: 6px;
    color: #99a6b1;
    color: #bccdda;
    display: block;
}

#checkout {
    text-decoration: none;
    color: #bccdda;
    display: block;
    margin: 5px 0px 0px 7px;
}

@media screen and (max-width: 499px) {
    #checkout {
        padding-bottom: 50px;
    }
}


#checkout .icon {
    margin-bottom: 4px;
}