#experience {
    padding: 150px 0px 50px 0px;
    display: flex;
    text-align: center;
}

#experience-row {
    align-self: center;
    min-width: 100%;
}

#experience-row * {
    margin: 0px auto;
}

#experience-row .col-1 {
    padding: 0px;
}

#experience .nav-link.active {
    background-color: #182a36 !important;
    color: #bccdda !important;
}

.pills {
    margin-bottom: 15px !important;
}

.pills * {
    margin: 0px !important;
    /* width: 20%; */
}

#experience-resume {
    padding: 5px;
    color: #72808a;
}

#experience .nav-link {
    padding: 8px 12px;
}

#pills-column {
    float: left;
}

#experience-main {
    float: right;
    margin-left: auto;
}

.experience-section {
    text-align: left;
    background-color: #8198a8;
    padding: 15px 8px;
    border-radius: 5px;
    color: #244259;
}

.experience-section h4 {
    color: #182a36;
}

.experience-section p {
    color: #516a78;
}

.experience-section-top {
    margin-top: 10px !important;
}

.nav-link h6 {
    margin: 0px;
}

.experience-img {
    max-width: 100%;
    border-radius: 5px;
}

.sub-section {
    padding: 15px 30px;
}

.sub-section p {
    color: white;
}

.entry-img {
    margin: 0px !important;
}

.entry-title {
    margin: auto 0px !important;
}

.experience-middle {
    margin: 20px 0px !important;
}

.section-list {

}

.section-list-item {
    display: inline-block;
    margin: 0px;
    padding: 0px;
}

.job {
    margin-bottom: 15px !important;
}

.section-list .sub-section-text {
    color: white;
    max-width: calc(100% - 16px);
    float: left;
}

.section-list-icon {
    padding: 2px;
    color: #244259;
    margin: 3px 3px 0px 0px !important;
    float: left;
}

.section-list-icon {
    padding: 2px;
    color: #244259;
    margin: 3px 3px 0px 0px !important;
    float: left;
}

.section-list-icon-large {
    padding: 2px;
    color: #244259;
    margin: 5px 3px 0px 0px !important;
    float: left;
    font-size: large;
}

.ordinary-title {
    margin-bottom: 10px !important;
}

.section-list-identifier {
    font-size: x-large;
    padding: 2px;
    color: #244259;
    margin: 3px 3px 0px 0px !important;
    float: left;
}

.section-list .sub-section-text-large {
    color: white;
    max-width: calc(100% - 22px);
    float: left;
    padding-top: 5px;
}

.section-list .sub-section-text-xlarge {
    color: white;
    max-width: calc(100% - 27px);
    float: left;
    padding-top: 5px;
}

.award-title {
    font-weight: 600;
    color: #182a36 !important;
    display: inline;
}

.qed-col {
    padding-right: 20px;
    padding-left: 20px;
}

.leadingpath-col {
    padding-right: 30px;
    padding-left: 20px;
}